
import './App.css';
import 'antd/dist/antd.min.css';
import { Card, Divider, Image, Layout, Modal, Spin, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { Col, Row, Input, Button, DatePicker, } from 'antd';
import HeaderModule from './header';
import type, { ColumnsType } from 'antd/es/table';
import { SearchOutlined, EyeOutlined } from '@ant-design/icons';
import { Radio } from 'antd';
import axios from 'axios';
import moment from 'moment';
import banner1 from './assets/banner1.jpeg';
import banner2 from './assets/banner2.jpeg';
import telephone from './assets/telephone.png';
import footer_banner from './assets/footer_xdkd.png';
import { APP_ID_SHEET, BASE_URL_SHEET } from './constant/constants';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as TooltipChart,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

interface DataType {
  id: String,
  code: String,
  name: String,
  eInvoiceDate: String,
  total: String,
  referenceCode: String,
  isInvoiceOld: Boolean,
  url: String
}
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  TooltipChart,
  Legend
);
export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      text: 'Biểu đồ tiêu thụ điện năng theo ngày(kWh)',
    },
  }
};
export const options2 = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      text: 'Biểu đồ chỉ số công tơ theo ngày(kWh)',
    },
  }
};

const App: React.FC = () => {

  const [customerCode, setCustomerCode] = useState('');
  const [phone, setPhone] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [listTotal, setListTotal] = useState([]);
  const [isShowloading, setIsShowloading] = useState(false);
  const [disableClick, setDisableClick] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [linkPdf, setLinkPdf] = useState("");
  const [option, setOption] = useState(1);
  const [accessToken, setAccessToken] = useState("");
  const [message, setMessage] = useState("");
  const [tabSelected, setTabSelected] = useState('invoice');
  const [optionTab, setOptionTab] = useState([
    { label: 'Tra cứu hoá đơn', value: 'invoice' },
    { label: 'Tra cứu số điện', value: 'power' },
  ])

  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [serieNumber, setSerieNumber] = useState('');
  const [dataPower, setDataPower] = useState([]);
  const [customerInfo, setCustomerInfo] = useState();
  const [soDienSuDung, setSoDienSuDung] = useState(0);
  const [messageError, setMessageError] = useState();
  const [dataChart, setDataChart] = useState({
    labels: [],
    datasets: [
      {
        label: 'Điện năng tiêu thụ',
        data: [],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      }
    ],
  });
  const [dataChartChiSo, setDataChartChiSo] = useState({
    labels: [],
    datasets: [
      {
        label: 'Chỉ số công tơ',
        data: [],
      }
    ],
  });

  const [browserType, setBrowserType] = useState();
  const [deviceType, setDeviceType] = useState();
  const [ipInfo, setIpInfo] = useState();
  
    
  const columns  = [
    {
      title: 'Mã khách hàng',
      dataIndex: 'ma_kh',
      key: 'ma_kh',
    },
    {
      title: 'Tên khách hàng',
      dataIndex: 'ten_kh',
      key: 'ten_kh',
    },
    {
      title: 'Ngày xuất hoá đơn',
      dataIndex: 'ngay_ct',
      key: 'ngay_ct',
    },
    {
      title: 'Số chứng từ',
      key: 'so_ct',
      dataIndex: 'so_ct',

    },
    {
      title: 'Tổng tiền',
      key: 't_tt',
      dataIndex: 't_tt',
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <Tooltip title="Xem hoá đơn">
          <Button disabled={disableClick} type="primary" shape="circle" onClick={() => onPressViewInvoice(record)} icon={<EyeOutlined />} />
        </Tooltip>
      ),
    },
  ];
  const columnsPower = [
    {
      title: 'Số serie công tơ',
      dataIndex: 'soCongTo',
      align: 'center'
    },
    {
      title: 'Thời gian đọc kết quả',
      dataIndex: 'thoiGianDoc',
      align: 'center'
    },
    {
      title: 'Chỉ số công tơ(kWh)',
      dataIndex: 'chiSoCongTo',
      align: 'center'
    }
  ];
  const onPressViewInvoice = (item) => {
    window.open(item.link_inv, "PRINT", "height=800,width=1200");

  }
  const handleOnchangeTab = (event) => {
    console.log('radio4 checked', event.target.value);
    setTabSelected(event.target.value)
  };
  const onChangeStartDate = (e) => {
    setStartDate(moment(e).format('YYYY-MM-DD'));
  }
  const onChangeEndDate = (e) => {
    setEndDate(moment(e).format('YYYY-MM-DD'));
  }
  const onChangeCode = (e) => {
    setCustomerCode(e.target.value);
  }
  const onChangePhone = (e) => {
    setPhone(e.target.value);
  }
  const onChangeOption = (e) => {
    setOption(e.target.value);
  };
  const iframe = `<iframe src="https://tracuuhddt.onfinance.asia/Search/?comtaxcode=0107655337&mkh=true&sdt=true" width="100%" height="500" style="min-height: 200; max-height: 800"></iframe>`;
  function loadWeb() {
    return { __html: iframe };
  }
  function Mywebsite() {
    return <div className='my__web' dangerouslySetInnerHTML={loadWeb()} style={{ display: option === 1 && tabSelected === 'invoice' ? 'block' : 'none' }} />;
  }

  const getAccessToken = () => {
    setIsShowloading(true);
    const params = new URLSearchParams();
    params.append('username', 'admin');
    params.append('password', '123');
    params.append('grant_type', 'password');
    axios.post('http://222.252.15.254:8088/FastAPI/token', params)
      .then(res => {
        setIsShowloading(false);
        if (res.status === 200) {
          setAccessToken(res.data.access_token);
        }
      })
      .catch(err => {
        setIsShowloading(false);
      })
  }
  const onChangeStartTime = (e) => {
    setStartTime(moment(e).format('YYYY-MM-DD'));
  }
  const onChangeEndTime = (e) => {
    setEndTime(moment(e).format('YYYY-MM-DD'));
  }
  const onChangeSerieNumber = (e) => {
    setSerieNumber(e.target.value);
  }

  const handleGetInfoPower = async () => {
    if (startTime.length == 0 || startTime === null) {
      alert("Vui lòng nhập ngày bắt đầu");
      return;
    }
    if (endTime.length == 0 || endTime === null) {
      alert("Vui lòng nhập ngày kết thúc");
      return;
    }
    if (serieNumber.length == 0 || serieNumber === 0) {
      alert("Vui lòng nhập mã khách hàng");
      return;
    }
    if (moment(startTime).isAfter(moment(endTime))) {
      alert("Ngày kết thúc không được nhỏ hơn ngày bắt đầu");
      return;
    }
    const start = moment(startTime).subtract(1, 'days');
    const end = moment(endTime);
    const dateArr = [];
    let currentDate = start;
    while (currentDate.isSameOrBefore(end)) {
      dateArr.push(currentDate.format('YYYY-MM-DD HH:mm:ss'));
      currentDate.add(1, 'day');
    }
    if (dateArr.length > 32) {
      alert("Bạn không thể tra cứu nhiều hơn 31 ngày. Vui lòng chọn lại ngày để tra cứu");
      return;
    }
    setDataPower([]);
    setCustomerInfo();
    getCustomerInfo(serieNumber);
  }
  const roundToTwoDecimalPlaces = (number) => Math.round(number * 100) / 100;
  const getData = async (serie, cusInfo) => {
    setIsShowloading(true);
    const baseUrl = BASE_URL_SHEET + APP_ID_SHEET + `/exec?type=GET_POWER&start=${moment(startTime).subtract(1, 'days').format("YYYY-MM-DD")}&end=${endTime}&serieNumber=${serie}`;
    fetch(baseUrl, {
      redirect: "follow",
      method: 'POST'
    })
      .then((response) => response.json())
      .then((res) => {
        if (res && res.status === "success" && res.data) {
          const newData = res.data.map(item => {
            const parser = new DOMParser();
            const xml = parser.parseFromString(item, 'text/xml');
            const soCongTo = xml.querySelector('METER_NO').textContent;
            const thoiGianDoc = xml.querySelector('DATE_TIME').textContent;
            const chiSoCongTo = xml.querySelector('E180').textContent;
            return {
              soCongTo,
              thoiGianDoc,
              chiSoCongTo
            }
          });
          let tempData = newData.sort((a, b) => {
            var dateA = new Date(a.thoiGianDoc);
            var dateB = new Date(b.thoiGianDoc);
            return dateA - dateB;
          });
          const soSuDung = parseFloat(tempData[tempData.length - 1]?.chiSoCongTo) - parseFloat(tempData[0]?.chiSoCongTo);
          setSoDienSuDung(soSuDung);
          setDataPower([...tempData.filter((item, index) => index > 0)]);
          const dataForChart = tempData?.map((value, index, array) => {
            const previousValue = index > 0 ? array[index - 1]?.chiSoCongTo : 0;
            return roundToTwoDecimalPlaces(parseFloat(value.chiSoCongTo) - parseFloat(previousValue)); 
          }).filter((item, index) => index > 0);
          const dataChiSoCongTo = tempData?.filter((item, index) => index > 0)?.map((val, index) => val.chiSoCongTo);
          const labelsForChart = tempData?.filter((item, index) => index > 0)?.map((value, index) => moment(value.thoiGianDoc).format("DD/MM"));
          setDataChart({
            labels: [...labelsForChart],
            datasets: [
              {
                label: 'Điện năng tiêu thụ',
                data: [...dataForChart],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              }
            ],
          });
          setDataChartChiSo({
            labels: [...labelsForChart],
            datasets: [
              {
                label: 'Chỉ số công tơ',
                data: [...dataChiSoCongTo],
                borderColor: 'blue',
                backgroundColor: 'blue',
              }
            ],
          });
          const dataLog = {
            code: cusInfo?.code,
            name: cusInfo?.fullname,
            serie: cusInfo?.serie,
            browser: browserType,
            search_type: "Tra cứu số điện",
            time_query: `${startTime} - ${endTime}`,
            device_type: deviceType,
            ip: ipInfo?.ip ?? '',
            ip_info: ipInfo?.ip_info ?? ''
          }
          saveLog(dataLog);
          setMessageError(null);
        } else {
          setDataPower([]);
          setMessageError(null);
          const dataLog = {
            code: cusInfo?.code ?? '',
            name: cusInfo?.fullname ?? '',
            serie: cusInfo?.serie ?? '',
            browser: browserType,
            search_type: "Tra cứu số điện",
            time_query: `${startTime} - ${endTime}`,
            device_type: deviceType,
            ip: ipInfo?.ip ?? '',
            ip_info: ipInfo?.ip_info ?? ''
          }
          saveLog(dataLog);
        }
        setIsShowloading(false);
      })
      .catch((error) => {
        setIsShowloading(false);
        setMessageError(null);
        const dataLog = {
          code: cusInfo?.code ?? '',
          name: cusInfo?.fullname ?? '',
          serie: cusInfo?.serie ?? '',
          browser: browserType,
          search_type: "Tra cứu số điện - Lỗi",
          time_query: `${startTime} - ${endTime}`,
          device_type: deviceType,
          ip: ipInfo?.ip ?? '',
          ip_info: ipInfo?.ip_info ?? ''
        }
        saveLog(dataLog);
      });
  }

  const getInfoIP = () => {
    fetch('https://ipinfo.io?token=0c90288cc75267')
    .then(response => response.json())
    .then(data => {
      console.log("data", data);
      if (data) {
        setIpInfo({
          ip: data.ip,
          ip_info: JSON.stringify(data)
        })
      } else {
        setIpInfo({
          ip: 'No Ip',
          ip_info: 'No Info'
        });
      }
    })
    .catch(err => {
      setIpInfo({
        ip: 'No Ip',
        ip_info: 'No Info'
      })
    });
  }

  const getCustomerInfo = (code) => {
    setIsShowloading(true)
    axios.get(`http://congtyxaylapkinhdoanhdien.vn/api/api/getInfo?code=${code}`)
      .then(res => {
        setIsShowloading(false);
        if (res && res.data && res.data.status === 'success' && res.data.data) {
          setCustomerInfo(res.data.data?.[0]);
          getData(res.data.data?.[0]?.serie, res.data.data?.[0]);
          setMessageError(null);
        } else {
          setMessageError(res.data.msg ?? 'Không tìm thấy thông tin khách hàng');
          const dataLog = {
            code: code,
            name: '',
            serie: '',
            browser: browserType,
            search_type: "Tra cứu số điện - Không tìm thấy info",
            time_query: `${startTime} - ${endTime}`,
            device_type: deviceType,
            ip: ipInfo?.ip ?? '',
            ip_info: ipInfo?.ip_info ?? ''
          }
          saveLog(dataLog);
        }
      })
      .catch(err => {
        setIsShowloading(false);
        setMessageError('Không tìm thấy thông tin khách hàng');
        const dataLog = {
          code: code,
          name: '',
          serie: '',
          browser: browserType,
          search_type: "Tra cứu số điện - Lỗi getInfo",
          time_query: `${startTime} - ${endTime}`,
          device_type: deviceType,
          ip: ipInfo?.ip ?? '',
          ip_info: ipInfo?.ip_info ?? ''
        }
        saveLog(dataLog);
      });
  }

  const searchEvoice = () => {
    if (startDate.length == 0) {
      alert("Vui lòng nhập ngày bắt đầu");
      return;
    }
    if (endDate.length == 0) {
      alert("Vui lòng nhập ngày kết thúc");
      return;
    }
    if (customerCode.length == 0) {
      alert("Vui lòng nhập mã khách hàng");
      return;
    }
    if (phone.length == 0) {
      alert("Vui lòng nhập số điện thoại");
      return;
    }

    setIsShowloading(true);
    const params = {
      StartDate: startDate,
      EndDate: endDate,
      CustCode: customerCode,
      PhoneNumber: phone
    }
    axios.get(`http://222.252.15.254:8088/FastAPI/API/GetEInvoice`, {
      params,
      headers: {
        'authorization': 'Bearer ' + accessToken
      }
    })
      .then(res => {
        if (res && res.status == 200) {
          if (res.data) {
            var formater = new Intl.NumberFormat('vi-VN', {
              style: 'currency',
              currency: 'VND',
              minimumFractionDigits: 2,
            })
            let temp = [];
            res.data.data.forEach(item => {
              item.t_tt = formater.format(item.t_tt);
              item.ngay_ct = new Date(item.ngay_ct).toLocaleDateString("vi-VN");
              temp.push(item);
            })
            setListTotal([...temp]);
            setIsShowloading(false);
          }
        } else if (res.status === 401) {
          setIsShowModal(true);
        }
        const dataLog = {
          code: customerCode,
          name: phone,
          serie: '',
          browser: browserType,
          search_type: "Tra cứu hoá đơn",
          time_query: `${startDate} - ${endDate}`,
          device_type: deviceType,
          ip: ipInfo?.ip ?? '',
          ip_info: ipInfo?.ip_info ?? ''
        }
        saveLog(dataLog);
      })
      .catch(e => {
        setIsShowloading(false);
        setMessage("Có lỗi xảy ra vui lòng thử lại!");
        if (e.response.status === 401) {
          setIsShowModal(true);
          setMessage("Hết phiên làm việc. Vui lòng tải lại trang web!");
        }
        const dataLog = {
          code: customerCode,
          name: phone,
          serie: '',
          browser: browserType,
          search_type: "Tra cứu hoá đơn - Lỗi",
          time_query: `${startDate} - ${endDate}`,
          device_type: deviceType,
          ip: ipInfo?.ip ?? '',
          ip_info: ipInfo?.ip_info ?? ''
        }
        saveLog(dataLog);
      });

  }

  const saveLog = (data) => {
    axios.post('http://congtyxaylapkinhdoanhdien.vn/api/api/saveLog', data)
      .then(res => {
        console.log('resss', res);
      })
      .catch(err => {
        console.log("err", err);
      })

  }

  useEffect(() => {
    getAccessToken();
    getInfoIP();
    const userAgent = navigator.userAgent;
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (userAgent.includes('Chrome')) {
      setBrowserType('Chrome');
    } else if (userAgent.includes('Firefox')) {
      setBrowserType('Firefox');
    } else if (userAgent.includes('Safari')) {
      setBrowserType('Safari');
    } else if (userAgent.includes('Edge')) {
      setBrowserType('Edge');
    } else if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
      setBrowserType('Opera');
    } else {
      setBrowserType('Other');
    }
    if (isMobile) {
      setDeviceType('Mobile');
    } else {
      setDeviceType('Desktop');
    }
  }, [])
  return (
    <Layout className='container'>
      <Row className='header'>
        <HeaderModule />
      </Row>

      <div className='content'>

        <Divider />
        <Row>
          <Radio.Group
            options={optionTab}
            onChange={handleOnchangeTab}
            value={tabSelected}
            optionType="button"
            buttonStyle="solid"
          />
        </Row>
        <div style={tabSelected === 'power' ? { display: 'none' } : null}>
          <Row>
            <p className='title__option'>Chọn thời điểm tra cứu</p>
          </Row>
          <Row>
            <Radio.Group onChange={onChangeOption} value={option}>
              <Radio value={1}>Trước tháng 10/2022</Radio>
              <Radio value={2}>Sau tháng 10/2022</Radio>

            </Radio.Group>
          </Row>
        </div>
        <Row style={tabSelected === 'power' ? { display: 'flex' } : { display: 'none' }}>

          <Col className='content' xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className='form__filter_power'>
              <Row gutter={{ xs: 32, sm: 32, md: 12, lg: 12 }} style={{ margin: 20 }}>
                <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ display: 'flex', flexDirection: 'column' }}>
                  <label className='label__filter'>Từ ngày</label>
                  <DatePicker placeholder='dd/mm/yyyy' format={['DD/MM/YYYY']} onChange={onChangeStartTime} allowClear={false} />
                </Col>
                <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ display: 'flex', flexDirection: 'column' }}>
                  <label className='label__filter'>Đến ngày</label>
                  <DatePicker placeholder='dd/mm/yyyy' format={['DD/MM/YYYY']} onChange={onChangeEndTime} allowClear={false} />
                </Col>
                <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ display: 'flex', flexDirection: 'column' }}>
                  <label className='label__filter'>Mã khách hàng</label>
                  <Input placeholder="Mã khách hàng" allowClear onChange={onChangeSerieNumber} maxLength={20} value={serieNumber} />
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'end', alignItems: 'center' }}>
                  <Row >
                    <Button icon={<SearchOutlined />} style={{ width: 200 }} size="large" onClick={handleGetInfoPower}>
                      Tra cứu số điện
                    </Button>
                  </Row>
                </Col>
              </Row>

            </div>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                {
                  customerInfo ?
                    <Card
                      type="inner"
                      title={`Khách hàng: ${customerInfo?.fullname ?? ''}`}
                      style={{ marginTop: 12 }}
                    >
                      <p>Thời gian từ ngày: {moment(startTime).format("DD/MM/YYYY")} - {moment(endTime).format("DD/MM/YYYY")}</p>
                      <p>Mã khách hàng: {customerInfo?.code ?? ''}</p>
                      <p>Số serie: {customerInfo?.serie ?? ''}</p>
                      <p>Số điện sử dụng: {soDienSuDung.toFixed(2)} kWh</p>
                    </Card> : null
                }
                {
                  messageError ? <p style={{color: 'red', fontSize: 14, textAlign: 'center'}}>{messageError}</p> : null
                }
                {
                  dataPower && dataPower.length > 0 ? 
                  <div style={{height: 400, width: '100%'}}>
                    <Line options={options} data={dataChart} plugins={[ChartDataLabels]}/>
                  </div> : null
                }
                {
                  dataPower && dataPower.length > 0 ? 
                  <div style={{height: 400, width: '100%', marginTop: 20}}>
                    <Line options={options2} data={dataChartChiSo} plugins={[ChartDataLabels]}/>
                  </div> : null
                }
                <Table columns={columnsPower} dataSource={dataPower} className='table__evoice' bordered rowClassName="table__evoice" pagination={{ pageSize: 50 }} />
              </Col>
            </Row>

          </Col>
        </Row>
        <Row style={option === 2 && tabSelected === 'invoice' ? { display: 'flex' } : { display: 'none' }}>

          <Col className='content' xs={24} sm={24} md={24} lg={24} xl={24}>

            <div className='form__filter'>
              <Row gutter={{ xs: 32, sm: 32, md: 12, lg: 12 }} style={{ margin: 20 }}>
                <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ display: 'flex', flexDirection: 'column' }}>
                  <label className='label__filter'>Từ ngày</label>
                  <DatePicker placeholder='dd/mm/yyyy' format={['DD/MM/YYYY']} onChange={onChangeStartDate} />
                </Col>
                <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ display: 'flex', flexDirection: 'column' }}>
                  <label className='label__filter'>Đến ngày</label>
                  <DatePicker placeholder='dd/mm/yyyy' format={['DD/MM/YYYY']} onChange={onChangeEndDate} />
                </Col>
                <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ display: 'flex', flexDirection: 'column' }}>
                  <label className='label__filter'>Mã khách hàng</label>
                  <Input placeholder="Mã khách hàng" allowClear onChange={onChangeCode} maxLength={20} value={customerCode} />
                </Col>
                <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ display: 'flex', flexDirection: 'column' }}>
                  <label className='label__filter'>Số điện thoại</label>
                  <Input placeholder="Số điện thoại" allowClear onChange={onChangePhone} maxLength={12} value={phone} />
                </Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'end', alignItems: 'center' }}>
                  <Row >
                    <Button icon={<SearchOutlined />} size="large" onClick={searchEvoice}>
                      Tra cứu
                    </Button>
                  </Row>
                </Col>
              </Row>

            </div>

            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Table columns={columns} dataSource={listTotal} className='table__evoice' bordered rowClassName="table__evoice" />
              </Col>
            </Row>

          </Col>
        </Row>

        <Row >
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Mywebsite />
          </Col>
        </Row>



      </div>
      <Row gutter={30}>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Image
            src={banner1}
          />
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Image
            src={banner2}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} className='col__department'>
          <Row className='row__item'>
            <label className='title__department'>Phòng kinh doanh</label>
          </Row>
          <Row className='row__item'>
            <label className='desc__department'>Tiếp nhận hồ sơ lắp đặt, bổ sung, thay đổi hợp đồng sử dụng điện, tiếp nhận ý kiến khách hàng sử dụng điện về chỉ số và tiền điện</label>
          </Row>
          <Row className='row__phone row__item'>
            <Image
              src={telephone}
              width={30}
            />
            <label className='phone__department'>0243.8722071</label>
          </Row>
          <Row className='row__item'>
            <label className='title__department'>Trực sửa chữa (hotline 24/7)</label>
          </Row>
          <Row className='row__item'>
            <label className='desc__department'>Tiếp nhận thông tin sự cố điện 24/7</label>
          </Row>
          <Row className='row__phone row__item'>
            <Image
              src={telephone}
              width={30}
            />
            <label className='phone__department'>024.22154855</label>
          </Row>
          <Row className='row__item'>
            <label className='title__department'>Phòng kế toán</label>
          </Row>
          <Row className='row__item'>
            <label className='desc__department'>Giải đáp thắc mắc về hóa đơn điện tử</label>
          </Row>
          <Row className='row__phone row__item'>
            <Image
              src={telephone}
              width={30}
            />
            <label className='phone__department'>0243.8720442 - 0396537638</label>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='col__info'>
          <p className='info_txt'>Quý khách có nhu cầu hỗ trợ các dịch vụ về lắp đặt, sửa chữa hệ thống điện liên hệ hotline 024.22154855 để được phục vụ!</p>
          <Button type="primary" style={{ width: 200, height: 40, fontSize: 16 }}><a className='link_reg' href='https://docs.google.com/forms/d/e/1FAIpQLSfj7lHuXx_5rfsFf-0pi-0NrTfwrUWngYQW-fH7Qz5ax81BMw/viewform' target="_blank">Đăng ký ngay</a></Button>
        </Col>

      </Row>
      <Row className='footer'>
        <img
          src={footer_banner}
          style={{ width: '100%' }}
        />
      </Row>
      {
        isShowloading ?
          <div className='loading'>
            <Spin size='large' />
          </div> : null
      }

      <Modal
        centered
        open={isShowModal}
        okText={"Ok"}
        cancelText={null}
        onOk={() => setIsShowModal(false)}
        onCancel={() => setIsShowModal(false)}
      >
        <p>{message}</p>
      </Modal>
    </Layout>

  );
}

export default App;


import { Col, Image, Row } from "antd";
import logo from '../assets/logo.png';

function HeaderModule() {

    return (
        <>
            
            <Col xs={24} sm={24} md={16} lg={12} xl={12}>
                <Row className='title__header'>
                    <Col span={4}>
                        <Image
                            src={logo}
                            className='logo-company'
                            
                        />
                    </Col>
                    <Col span={20}>
                        <Row>
                            <label className='title__port'>CỔNG THÔNG TIN TRA CỨU TIỀN ĐIỆN</label>
                        </Row>
                        <Row>
                            <label className='title__name'>CÔNG TY TNHH MỘT THÀNH VIÊN XÂY LẮP KINH DOANH ĐIỆN</label>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col xs={24} sm={24} md={8} lg={12} xl={12} className='header__contact'>

                <Row>
                    <Col>
                        <label className="contact__text">Hotline 24/7: 0242 2154 855</label>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label className="contact__text">CSKH: 0243 8722 071</label>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label className="contact__text">BP Kế toán: 0243 8720 442</label>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label className="contact__text">ZALO CÔNG TY: 0396537638</label>
                    </Col>
                </Row>
            </Col>
            
        </>
    )
}
export default HeaderModule;